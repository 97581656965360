import api, { ApiCallReturn } from "../api";

export const getDataUser = (): ApiCallReturn => api.get('/auth/user');
export const getUserWallet = (userId: number): ApiCallReturn => api.get(`/users/${userId}/wallet`);
export const getUserGames = (userId: number): ApiCallReturn => api.get(`/users/${userId}/games`);
export const getUserGame = (userId: number, gameId: number): ApiCallReturn => api.get(`/users/${userId}/games/${gameId}`);
export const postUserCompetition = (userId: number, competitionId: number, params: Record<string, string|number> = {}): ApiCallReturn =>
  api.post(`/users/${userId}/competitions/${competitionId}`, params);
export const postUserRanked = (userId: number, competitionId: number, params: Record<string, string|number> = {}): ApiCallReturn =>
  api.post(`/users/${userId}/ranked/${competitionId}`, params);
export const postUserMultiplayer = (userId: number, competitionId: number, params: Record<string, string|number> = {}): ApiCallReturn =>
  api.post(`/users/${userId}/multiplayer/${competitionId}`, params);
export const getCheckFriendCode = (params: Record<string, string>): ApiCallReturn => api.get('/friend-codes/check-code', {params});
export const postUserAvatar = (userId: number, avatar: string): ApiCallReturn => api.post(`/auth/user/${userId}`, {avatar});
export const getChatData = (type: string, params: Record<string, any>): ApiCallReturn => api.get(`/chats/chat-data/${type}`, {params});
export const followUser = (userId: number): ApiCallReturn => api.post(`/users/${userId}/follow`);
export const unfollowUser = (userId: number): ApiCallReturn => api.post(`/users/${userId}/unfollow`);
export const postUserGames = (userId: number, gameId: number, settings: Record<string, any>): ApiCallReturn => api.post(`/users/${userId}/games/${gameId}`, settings);
export const patchUserGames = (userId: number, gameId: number, settings: Record<string, any>): ApiCallReturn => api.patch(`/users/${userId}/games/${gameId}`, settings);
export const postWalletUserDeposit = (method: string, data: Record<string, any>): ApiCallReturn => api.post(`/transaction-wallet/user/deposit`, {
  method,
  data
});
export const postUserTransactionsWallet = (userId: number, settings: Record<string, any>): ApiCallReturn => api.post(`/transaction-wallet/${userId}`, settings);
export const postSupport = (settings: Record<string, string>): ApiCallReturn => api.post(`/support`, settings);
export const getUserFollowers = (userId: number): ApiCallReturn => api.get(`/users/${userId}/followers`);
export const getUserFollowing = (userId: number): ApiCallReturn => api.get(`/users/${userId}/following`);
export const getUserBlocked = (userId: number): ApiCallReturn => api.get(`/users/${userId}/blocked`);
export const postBlockUser = (blockUsername: number|string, gamesBlocked: number[]): ApiCallReturn => api.post(`/users/${blockUsername}/block`, {games_blocked: gamesBlocked});
export const patchUser = (userId: number, settings: Record<string, string>): ApiCallReturn => api.post(`/auth/user/${userId}`, settings);
export const getUserCompetitionsHistory = (username: string, params: Record<string, any>): ApiCallReturn => api.get(`/users/${encodeURIComponent(username)}/competitions/history`, {params});
export const getUserActiveCompetitions = (): ApiCallReturn => api.get('/users/competitions/active');
export const postVerifyUserIdentityRequest = (data: Record<string, string>): ApiCallReturn => api.post('/users/identity-verification-request', data);
export const getUsersToChallange = (params: any = {}): ApiCallReturn => api.get(`/users/challenges`, {params});
export const searchUsers = (filter: string, limit: number, offset: number): ApiCallReturn => api.get(`/users/search`, {params: {filter, limit, offset}});
export const postUserSource = (source: Record<string, any>): ApiCallReturn => api.post('/users/source', {user_source: source});
export const postReporterUser = (data: Record<string, string|number>): ApiCallReturn => api.post('/reporter-users', data);
export const postRoulettePrize = (data: Record<string, string|number>): ApiCallReturn => api.post('/users/new-roulette-prize', data);
export const deleteAccount = (userId: number): ApiCallReturn => api.delete(`/users/${userId}/delete-account`);
