import Vue, { h } from 'vue';
import VueRouter, { RouteConfig, START_LOCATION } from 'vue-router';
import { Middleware, MiddlewareContext } from "@/router/types";
import { ROUTES } from "./routes";
import { auth, anonymous, allowDeposit } from './middlewares';
import { store } from '@/store';

import Home from '@/views/Home.vue';
import Lobby from "@/views/Lobby.vue";
import Arena from "@/views/Arena.vue";
import { AppActionTypes } from "@/store/modules/app/actions";
import { APP_LANGS, LANGS } from '@/utils/i18n';
import i18n, { setLocale } from '@/services/i18n';

Vue.use(VueRouter);

const children: RouteConfig[] = [
  {
    ...ROUTES.home,
    component: Home,
    props: true,
    meta: {middleware: [anonymous]},
  },
  {
    ...ROUTES.login,
    component: () => import('../views/Login.vue'),
    meta: {middleware: [anonymous]},
  },
  {
    ...ROUTES.verifyAccount,
    component: () => import('../views/VerifyAccount.vue'),
    meta: {middleware: [anonymous]},
  },
  {
    ...ROUTES.forgotPassword,
    component: () => import('../views/ForgotPassword.vue'),
    meta: {middleware: [anonymous]},
  },
  {
    ...ROUTES.signup,
    component: () => import('../views/SignUp.vue'),
    props: true,
    meta: {middleware: [anonymous]},
  },
  {
    ...ROUTES.refer,
    props: true,
    meta: {middleware: [anonymous]},
    beforeEnter: (to, from, next) => {
      const {referUsername} = to.params;
      const name = store.getters.isDesktop ? ROUTES.home.name : ROUTES.signup.name;
      next({name, params: {referUsername}, query: to.query});
    },
  },
  {
    ...ROUTES.landingWelcome,
    component: () => import('../views/LandingWelcome.vue'),
    props: true,
    meta: {middleware: [anonymous]},
  },
  {
    ...ROUTES.lobby,
    component: Lobby,
  },
  {
    ...ROUTES.arena,
    props: true,
    component: Arena,
  },
  {
    ...ROUTES.minigame,
    props: true,
    component: () => import('../views/Minigame.vue'),
  },
  {
    ...ROUTES.competition,
    component: () => import('../views/Competition.vue'),
  },
  {
    ...ROUTES.ranked,
    component: () => import('../views/Ranked.vue'),
  },
  {
    ...ROUTES.multiplayer,
    props: true,
    component: () => import('../views/Multiplayer.vue'),
  },
  {
    ...ROUTES.competitionChat,
    component: () => import('../views/CompetitionChat.vue'),
    props: true,
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.competitionMatch,
    component: () => import('../views/Competition.vue'),
    props: true,
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.competitions,
    component: () => import('../views/Competitions.vue'),
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.competitionsHistory,
    component: () => import('../views/CompetitionsHistory.vue'),
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.profile,
    component: () => import('../views/Profile.vue'),
    props: true,
  },
  {
    ...ROUTES.followers,
    component: () => import('../views/Followers.vue'),
    props: true,
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.account,
    component: () => import('../views/Account.vue'),
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.create,
    component: () => import('../views/CreateMatch.vue'),
    props: true,
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.practiceGame,
    component: () => import('../views/PracticeGame.vue'),
    props: true,
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.playGame,
    component: () => import('../views/PlayGame.vue'),
    props: true,
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.wallet,
    component: () => import('../views/Wallet.vue'),
    meta: {middleware: [auth]}
  },
  {
    ...ROUTES.walletSuccess,
    component: () => import('../views/Wallet.vue'),
    meta: {middleware: [auth]}
  },
  {
    ...ROUTES.deposit,
    component: () => import('../views/Deposit.vue'),
    props: true,
    meta: {middleware: [allowDeposit, auth]}
  },
  {
    ...ROUTES.withdraw,
    component: () => import('../views/Withdraw.vue'),
    meta: {middleware: [auth]}
  },
  {
    ...ROUTES.messages,
    component: () => import('../views/Messages.vue'),
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.messagesUser,
    component: () => import('../views/MessagesUser.vue'),
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.verifyIdentity,
    component: () => import('../views/VerifyIdentity.vue'),
    props: true,
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.notificationCenter,
    component: () => import('../views/NotificationCenter.vue'),
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.promo,
    component: () => import('../views/Promo.vue'),
    props: true,
    meta: {middleware: [auth]},
  },
  {
    ...ROUTES.aboutUs,
    component: () => import('../views/AboutUs.vue'),
  },
  {
    ...ROUTES.help,
    component: () => import('../views/Help.vue'),
  },
  {
    ...ROUTES.support,
    component: () => import('../views/Support.vue'),
  },
  {
    ...ROUTES.terms,
    component: () => import('../views/Terms.vue'),
  },
  {
    ...ROUTES.cookies,
    component: () => import('../views/Cookies.vue'),
  },
  {
    ...ROUTES.privacy,
    component: () => import('../views/Privacy.vue'),
  },
  {
    ...ROUTES.recoverAccount,
    component: () => import('../views/RecoverAccount.vue')
  },
  {
    ...ROUTES.error,
    component: () => import('../views/Error.vue'),
  },
  {
    ...ROUTES.maintenanceMode,
    component: () => import('../views/MaintenanceMode.vue'),
  },
];

if (process.env.VUE_APP_BLOG_ENDPOINT) {
  children.push({
    ...ROUTES.blogTag,
    props: true,
    component: () => import('../views/BlogHome.vue'),
  });

  children.push({
    ...ROUTES.blogPost,
    props: true,
    component: () => import('../views/BlogPost.vue'),
  });

  children.push({
    ...ROUTES.blogHome,
    props: true,
    component: () => import('../views/BlogHome.vue'),
  });
}

if (process.env.VUE_APP_HAS_INVITE_FRIEND) {
  children.push({
    ...ROUTES.inviteFriend,
    component: () => import('../views/InviteFriend.vue'),
  })
}

if (process.env.VUE_APP_HAS_CHALLENGES) {
  children.push({
    ...ROUTES.challenges,
    component: () => import('../views/Challenges.vue'),
    meta: {middleware: [auth]},
  });
}

if (process.env.VUE_APP_HAS_ROULETTE) {
  children.push({
    ...ROUTES.roulette,
    component: () => import('../views/Roulette.vue'),
    meta: {middleware: [auth]},
  });
}

if (process.env.VUE_APP_HAS_LAST_PRIZES_DISTRIBUTED) {
  children.push({
    ...ROUTES.lastPrizesDistributed,
    component: () => import('../views/LastPrizesDistributed.vue'),
    meta: {middleware: [auth]},
  });
}

if (process.env.VUE_APP_HAS_PROMOTIONS) {
  children.push({
    ...ROUTES.promotions,
    component: () => import('../views/Promotions.vue'),
  });
}

children.push({
  path: '*',
  redirect: ROUTES.error.path,
});

const routes: RouteConfig[] = [{
  path: `/:lang(${APP_LANGS.filter(lang => lang !== LANGS.ES).join('|')})?`,
  component: {
    render() {
      return h('router-view');
    }
  },
  props: true,
  children,
}];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else {
      return {x: 0, y: 0}
    }
  }
});

const middlewarePipeline = (context: MiddlewareContext, middleware: Array<Middleware>, index: number) => {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    nextMiddleware({
      ...context,
      next: middlewarePipeline(context, middleware, index + 1),
    });
  };
};

router.beforeEach((to, from, next) => {
  if (from === START_LOCATION) {
    const language = to.params.lang;
    if (language) {
      if (language !== i18n.locale) {
        setLocale(language);
      }
    } else if (i18n.locale !== LANGS.ES) {
      const url = {...to, params: {...to.params, lang: i18n.locale}};
      return next(url);
    }
  }

  if (store.getters.isDesktop && (to.name != from.name)) {
    store.dispatch(AppActionTypes.SET_BREADCRUMB, []);
  }

  let middleware: Array<Middleware> | Middleware = to?.meta?.middleware;

  if (!middleware) {
    return next();
  }

  middleware = Array.isArray(middleware)
    ? middleware
    : [middleware];

  const context: MiddlewareContext = {to, from, next, store};
  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
