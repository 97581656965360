
  import Vue from "vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaUserLevel from "@/components/user/PwaUserLevel.vue";
  import PwaPrice from '@/components/common/PwaPrice.vue';
  import { ROUTES } from '@/router/routes';
  import { MINIGAMES_ALIAS } from '@/utils/minigames';

  export default Vue.extend({
    name: "pwa-user-active",
    components: {
      PwaIcon,
      PwaPrice,
      PwaUserLevel
    },
    props: {
      activeUserId: {
        type: Number,
        required: true
      },
      activeUserOnline: {
        type: Boolean,
        required: false,
        default: false
      },
      activeUsername: {
        type: String,
        required: true
      },
      activeUserAvatar: {
        type: String,
        required: false,
      },
      activeUserLevel: {
        type: Number,
        required: false,
        default: 0,
      },
      activeUserGameMode: {
        type: String || null,
        required: true
      },
      activeUserCostInscription: {
        type: Number || null,
        required: true
      },
      gameAlias: {
        type: String || null,
        required: false
      },
      gameConfigured: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    methods: {
      goToUserProfile(username: string) {
        if (username) {
          this.$router.push({name: ROUTES.profile.name, params: {lang: this.routeLang, username}});
        }
      },
      onClickChallengeUserClick() {
        this.createMatch({
          gameAlias: this.gameAlias,
          gameConfigured: this.gameConfigured,
          competitionProposal: {
            username: this.activeUsername,
          }
        });
      },
    },
    computed: {
      isMysteryMinigame(): boolean{
        return this.gameAlias == MINIGAMES_ALIAS.MYSTERY;
      }
    }
  });
