import { render, staticRenderFns } from "./Arena.vue?vue&type=template&id=2cb0c50b&scoped=true&"
import script from "./Arena.vue?vue&type=script&lang=ts&"
export * from "./Arena.vue?vue&type=script&lang=ts&"
import style0 from "./Arena.vue?vue&type=style&index=0&id=2cb0c50b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cb0c50b",
  null
  
)

export default component.exports