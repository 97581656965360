import UnityWebgl from 'unity-webgl';

export const MINIGAMES_ALIAS = {
  MYSTERY: 'mystery',
  WETRIS: 'wetris',
  FLAPPY_GOAL: 'flappy-goal',
  TAP_TAP: 'tap-tap',
  BITE_MAN: 'bite-man',
  SERPENT: 'serpent',
  WELIX_JUMP: 'welix-jump',
  CHRONO: 'chrono',
  STACKER: 'stacker',
  SOLITAIRE: 'solitaire',
};

export const MINIGAMES_ALLOW_LANG = {
  [MINIGAMES_ALIAS.BITE_MAN]: true,
  [MINIGAMES_ALIAS.SERPENT]: true,
  [MINIGAMES_ALIAS.WELIX_JUMP]: true,
  [MINIGAMES_ALIAS.CHRONO]: true,
  [MINIGAMES_ALIAS.STACKER]: true,
  [MINIGAMES_ALIAS.SOLITAIRE]: true
};

export const MINIGAMES_ALLOW_COMPETITION_ID = {
  [MINIGAMES_ALIAS.SOLITAIRE]: true
};

export type MinigamesUnityContextType = {
  loaderUrl: string;
  dataUrl: string
  frameworkUrl: string;
  codeUrl: string;
};

export const MINIGAMES_UNITY_CONTEXT: Record<string, MinigamesUnityContextType> = {
  [MINIGAMES_ALIAS.WETRIS]: {
    loaderUrl: "/games/wetris/Build/SolvedBuildWindderGzip.loader.js",
    dataUrl: "/games/wetris/Build/SolvedBuildWindderGzip.data.gz",
    frameworkUrl: "/games/wetris/Build/SolvedBuildWindderGzip.framework.js.gz",
    codeUrl: "/games/wetris/Build/SolvedBuildWindderGzip.wasm.gz",
  },
  [MINIGAMES_ALIAS.FLAPPY_GOAL]: {
    loaderUrl: "/games/flappy-goal/Build/BuildFinalFixTouch.loader.js",
    dataUrl: "/games/flappy-goal/Build/BuildFinalFixTouch.data.gz",
    frameworkUrl: "/games/flappy-goal/Build/BuildFinalFixTouch.framework.js.gz",
    codeUrl: "/games/flappy-goal/Build/BuildFinalFixTouch.wasm.gz",
  },
  [MINIGAMES_ALIAS.TAP_TAP]: {
    loaderUrl: "/games/tap-tap/Build/BuiltHotFix16.loader.js",
    dataUrl: "/games/tap-tap/Build/BuiltHotFix16.data.gz",
    frameworkUrl: "/games/tap-tap/Build/BuiltHotFix16.framework.js.gz",
    codeUrl: "/games/tap-tap/Build/BuiltHotFix16.wasm.gz",
  },
  [MINIGAMES_ALIAS.BITE_MAN]: {
    loaderUrl: "/games/bite-man/Build/Bite Man.loader.js",
    dataUrl: "/games/bite-man/Build/Bite Man.data.gz",
    frameworkUrl: "/games/bite-man/Build/Bite Man.framework.js.gz",
    codeUrl: "/games/bite-man/Build/Bite Man.wasm.gz",
  },
  [MINIGAMES_ALIAS.SERPENT]: {
    loaderUrl: "/games/serpent/Build/Serpent.loader.js",
    dataUrl: "/games/serpent/Build/Serpent.data.gz",
    frameworkUrl: "/games/serpent/Build/Serpent.framework.js.gz",
    codeUrl: "/games/serpent/Build/Serpent.wasm.gz",
  },
  [MINIGAMES_ALIAS.WELIX_JUMP]: {
    loaderUrl: "/games/welix-jump/Build/Welix Jump.loader.js",
    dataUrl: "/games/welix-jump/Build/Welix Jump.data.gz",
    frameworkUrl: "/games/welix-jump/Build/Welix Jump.framework.js.gz",
    codeUrl: "/games/welix-jump/Build/Welix Jump.wasm.gz",
  },
  [MINIGAMES_ALIAS.CHRONO]: {
    loaderUrl: "/games/chrono/Build/Chronometer.loader.js",
    dataUrl: "/games/chrono/Build/Chronometer.data.gz",
    frameworkUrl: "/games/chrono/Build/Chronometer.framework.js.gz",
    codeUrl: "/games/chrono/Build/Chronometer.wasm.gz",
  },
  [MINIGAMES_ALIAS.STACKER]: {
    loaderUrl: "/games/stacker/Build/Cube Stack.loader.js",
    dataUrl: "/games/stacker/Build/Cube Stack.data.gz",
    frameworkUrl: "/games/stacker/Build/Cube Stack.framework.js.gz",
    codeUrl: "/games/stacker/Build/Cube Stack.wasm.gz",
  },
  [MINIGAMES_ALIAS.SOLITAIRE]: {
    loaderUrl: "/games/solitaire/Build/Solitaire.loader.js",
    dataUrl: "/games/solitaire/Build/Solitaire.data.gz",
    frameworkUrl: "/games/solitaire/Build/Solitaire.framework.js.gz",
    codeUrl: "/games/solitaire/Build/Solitaire.wasm.gz",
  }
};

export const getMinigameInstance = (minigameAlias: string): UnityWebgl => new UnityWebgl(MINIGAMES_UNITY_CONTEXT[minigameAlias]);
