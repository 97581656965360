
  import Vue from "vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";

  export default Vue.extend({
    name: "pwa-user-level",
    components: {
      PwaIcon,
    },
    props: {
      userLevel: {
        type: Number,
        required: true
      },
      maxUserLevel: {
        type: Number,
        required: false,
        default: 5,
      },
      removeTitle: {
        type: Boolean,
        required: false,
        default: false,
      },
      isMysteryGame: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  });
