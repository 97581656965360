
  import Vue from "vue";

  export default Vue.extend({
    name: "pwa-modals",
    components: {
      PwaLoginModal: () => import("@/components/modals/PwaLoginModal.vue"),
      PwaSignUpModal: () => import("@/components/modals/PwaSignUpModal.vue"),
      PwaForgotPasswordModal: () => import("@/components/modals/PwaForgotPasswordModal.vue"),
      PwaCreateMatchModal: () => import("@/components/modals/PwaCreateMatchModal.vue"),
      PwaWalletModal: () => import("@/components/modals/PwaWalletModal.vue"),
      PwaDepositModal: () => import("@/components/modals/PwaDepositModal.vue"),
      PwaDepositReminderModal: () => import("@/components/modals/PwaDepositReminderModal.vue"),
      PwaWithdrawModal: () => import("@/components/modals/PwaWithdrawModal.vue"),
      PwaAddGameModal: () => import("@/components/modals/PwaAddGameModal.vue"),
      PwaInstallPwaModal: () => import("@/components/modals/PwaInstallPwaModal.vue"),
      PwaAllowAdsModal: () => import("@/components/modals/PwaAllowAdsModal.vue"),
      PwaAdsBlockedModal: () => import("@/components/modals/PwaAdsBlockedModal.vue"),
      PwaUserBlockManagementModal: () => import("@/components/modals/PwaUserBlockManagementModal.vue"),
      PwaUserPromotionModal: () => import("@/components/modals/PwaUserPromotionModal.vue"),
    },
  });
