import Vue from 'vue';
import { AppActionTypes } from "@/store/modules/app/actions";
import { ModalsSeenActionTypes } from '@/store/modules/modalsSeen/actions';
import {
  formatUserDateTime,
  formatUserDate,
  formatUserTime,
  getUserTimezone,
  getUserDate,
} from "@/utils/date";
import {
  getUrlImg,
  getDefaultAvatar,
  formatPrice,
  getBrandImageSrc,
  getCommonImageSrc,
  isPrerender,
  isIOS,
} from "@/utils/common";
import { MODAL_IDS, MODALS_LOADED, MODALS_SEEN_MINUTES } from "@/utils/modal";
import { ShowModalOptions } from "@/mixins/types";
import { BvToastOptions } from "bootstrap-vue";
import { ROUTES } from "@/router/routes";
import { Location } from "vue-router";
import { MIN_COST_INSCRIPTION } from "@/utils/competition";
import PwaInscriptionNoMoney from "@/components/modals/competitions/PwaInscriptionNoMoney.vue";
import moment from 'moment-timezone';
import { BRAND_IDS } from '@/utils/brand';
import { AuthActionTypes } from '@/store/modules/auth/actions';
import { isNativeApp, isLegacyNativeApp, TO_NATIVE_APP_EVENTS } from '@/utils/native-app';
import { getRouteLang } from '@/utils/i18n';
import { hasAds } from '@/services/ads';
import { openLink } from '@/utils/app-launcher';

const showModalTimeouts: Record<string, ReturnType<typeof setTimeout>> = {};

Vue.mixin(Vue.extend({
  computed: {
    isDesktop() {
      return this.$store.getters.isDesktop;
    },
    isTablet() {
      return this.$store.getters.isTablet;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn && this.$store.getters.userId;
    },
    isNativeApp() {
      return isNativeApp || isLegacyNativeApp();
    },
    canInstallApp() {
      const hasIosApp = Boolean(process.env.VUE_APP_IOS_APP_URL);
      const hasAndroidApp = Boolean(process.env.VUE_APP_ANDROID_APP_URL);

      return (isIOS && hasIosApp) || (!isIOS && hasAndroidApp);
    },
    acceptCookies() {
      return isPrerender ? true : this.$store.getters.acceptCookies;
    },
    userId() {
      return this.$store.getters.userId;
    },
    username() {
      return this.$store.getters.username;
    },
    currentLang() {
      return this.$i18n.locale;
    },
    routeLang() {
      return getRouteLang(this.currentLang);
    },
    walletQuantity() {
      return this.$store.state.user.wallet.quantity;
    },
    hasFreeMatch() {
      return this.$store.state.user.wallet.has_free_inscriptions;
    },
    freeMatchQuantity() {
      return this.$store.state.user.wallet.free_inscriptions_quantity;
    },
    appLogo(): string {
      return this.getBrandImageSrc('logo.svg');
    },
    appMobileLogo(): string {
      return this.getBrandImageSrc('mobile-logo.svg');
    },
    appName(): string {
      return process.env.VUE_APP_NAME;
    },
    appCompany(): string {
      return process.env.VUE_APP_COMPANY;
    },
    brandId(): number {
      return this.$store.state.app.config.id;
    },
    brandIds() {
      return BRAND_IDS;
    },
    isWinnder() {
      return this.brandId == BRAND_IDS.WINDER;
    },
    isGamersfy() {
      return this.brandId == BRAND_IDS.GAMERSFY_APP || this.brandId == BRAND_IDS.GAMERSFY_WEB;
    },
    isColiseum() {
      return this.brandId == BRAND_IDS.COLISEUM;
    },
    adsLoaded() {
      return this.$store.state.app.adsLoaded;
    },
    hasAds() {
      return hasAds && Boolean(this.$store.state.app.showAds) && !isPrerender;
    },
    hasWebAds() {
      return this.hasAds && !this.isNativeApp;
    },
    allowDeposit(): boolean {
      return this.$store.getters.allowDeposit;
    },
    hasCrossSelling(): boolean {
      return Boolean(process.env.VUE_APP_CROSS_SELLING_URL);
    },
    appVisible(): boolean {
      return this.$store.getters.appVisible;
    },
  },
  methods: {
    async showLoader(show: boolean) {
      await this.$store.dispatch(AppActionTypes.SET_LOADING, show);
    },
    showToast(title: string, message: string, level = 'info', duration = 5000, extraOptions: BvToastOptions = {}) {
      this.$root.$bvToast.toast(message, {
        title: title,
        variant: level,
        autoHideDelay: duration,
        solid: true,
        ...extraOptions,
      });
    },
    showToastError(title: string, message: string, duration = 5000, extraOptions: BvToastOptions = {}) {
      this.showToast(title, message, 'danger', duration, extraOptions);
    },
    showToastSuccess(title: string, message: string, duration = 5000, extraOptions: BvToastOptions = {}) {
      this.showToast(title, message, 'success', duration, extraOptions);
    },
    getUserTimezone(): string {
      return getUserTimezone();
    },
    getUserDate(date: string, dateFormat?: string, dateTimezone?: string): moment.Moment {
      return getUserDate(date, dateFormat, dateTimezone);
    },
    formatUserDateTime(date: string, dateFormat?: string, dateTimezone?: string): string {
      return formatUserDateTime(date, dateFormat, dateTimezone);
    },
    formatUserDate(date: string, dateFormat?: string, dateTimezone?: string): string {
      return formatUserDate(date, dateFormat, dateTimezone);
    },
    formatUserTime(date: string, dateFormat?: string, dateTimezone?: string): string {
      return formatUserTime(date, dateFormat, dateTimezone);
    },
    getUrlImg(segment: string, img: string): string {
      return getUrlImg(segment, img);
    },
    getAvatar(avatar: any): string {
      return avatar
        ? this.getUrlImg('avatars', avatar)
        : getDefaultAvatar();
    },
    formatPrice(amount: number, addSymbol = false, numDecimals = 2, removeDecimalsIfPossible = false, amountConverted = false): string {
      let currency = this.$store.state.app.config.currency;
      currency = {...currency, decimals: numDecimals};

      return formatPrice({amount, currency}, addSymbol, removeDecimalsIfPossible, amountConverted);
    },
    goToMessageUser(username: string): void {
      const route = this.isDesktop
        ? `/${ROUTES.messages.path}#${encodeURIComponent(username)}`
        : `/${ROUTES.messagesUser.path.replace(':username', encodeURIComponent(username))}`;

      this.routerPathPush(route);
    },
    createMatch({gameAlias = null, gameConfigured = false, competitionProposal = null} = {}): void {
      if(!this.isLoggedIn){
        this.goToSignup();
      }else{
        // Si no tiene cuota mínima le mandamos a ingresar
        if (this.allowDeposit && this.walletQuantity < MIN_COST_INSCRIPTION && !this.hasFreeMatch) {
          const message = this.$createElement(PwaInscriptionNoMoney, {
            props: {
              create: true,
              costInscription: MIN_COST_INSCRIPTION,
            },
          });
          this.showModal({
            id: 'modal-no-money',
            message,
            showOnRoot: true,
            contentClass: {'no-money': true, 'is-desktop': this.isDesktop, 'hide-footer': true},
          });
          // Si tiene saldo le llevamos a crear partida
        } else {
          const params: Record<string, any> = {};
          if (gameAlias) {
            params.defaultGameAlias = gameAlias;
            params.gameConfigured = gameConfigured;
          }

          if (competitionProposal) {
            params.competitionProposal = competitionProposal;
          }

          if (this.isDesktop) {
            this.showModalById(MODAL_IDS.CREATE_MATCH, params);
          } else {
            this.$router.push({name: ROUTES.create.name, params: {...params, lang: this.routeLang}});
          }
        }
      }
    },
    goToSignup(): void {
      if (this.isDesktop) {
        this.showModalById(MODAL_IDS.SIGN_UP);
      } else {
        this.$router.push({name: ROUTES.signup.name, params: {lang: this.routeLang}});
      }
    },
    showModal(options: ShowModalOptions = {}): void {
      const {message, hideCancel, onOkClick, onCancelClick, showOnRoot, ...modalOptions} = options;
      let modalMessage: any = message;
      if (!message) {
        modalOptions.bodyClass = 'modal-body-no-content';
        modalMessage = modalOptions.title;
      }

      if (!modalOptions.okTitle) {
        modalOptions.okTitle = this.$t('83');
      }

      if (!hideCancel && !modalOptions.cancelTitle) {
        modalOptions.cancelTitle = this.$t('82');
      }

      const modalService = showOnRoot ? this.$root.$bvModal : this.$bvModal;

      const modal = hideCancel
        ? modalService.msgBoxOk(modalMessage, modalOptions)
        : modalService.msgBoxConfirm(modalMessage, modalOptions);

      modal.then(accepted => {
        const callback: () => void | undefined = accepted ? onOkClick : onCancelClick;

        if (callback) {
          callback();
        }
      }).catch(() => {
        // TODO
      });
    },
    showModalById(id: string, modalProps: Record<string, any> = null): void {
      const showModalByIdRetry = () => {
        if (MODALS_LOADED[id]) {
          this.$root.$bvModal.show(id);
        } else {
          this.$nextTick(() => {
            setTimeout(() => showModalByIdRetry(), 200);
          });
        }
      };

      if (modalProps === null) {
        showModalByIdRetry()
      } else {
        this.$store.dispatch(AppActionTypes.SET_MODAL_PROPS, modalProps)
          .finally(() => showModalByIdRetry());
      }
    },
    hideModalById(id: string, removeModalProps = true): void {
      this.$root.$bvModal.hide(id);
      if (removeModalProps) {
        this.$store.dispatch(AppActionTypes.SET_MODAL_PROPS, {});
      }
    },
    showModalByIdWithDelay(id: string, modalProps: Record<string, any> = null, delay = 0): void {
      this.cancelShowModalByIdWithDelay(id);
      showModalTimeouts[id] = setTimeout(() => {
        this.showModalById(id, modalProps);
        this.cancelShowModalByIdWithDelay(id);
      }, delay);
    },
    cancelShowModalByIdWithDelay(id: string): void {
      if (showModalTimeouts[id]) {
        clearTimeout(showModalTimeouts[id]);
        showModalTimeouts[id] = null;
      }
    },
    scrollToTop(): void {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    setPageData(data = {}): void {
      this.$store.dispatch(AppActionTypes.SET_PAGE_DATA, data);
    },
    setNextUrl(path: string): void {
      this.$store.dispatch(AppActionTypes.SET_NEXT_URL, path);
    },
    goToNextUrl() {
      this.routerPathPush(this.$store.getters.nextUrl);
      this.setNextUrl('');
    },
    getBrandImageSrc(imagePath: string): string {
      return getBrandImageSrc(imagePath);
    },
    getCommonImageSrc(imagePath: string): string {
      return getCommonImageSrc(imagePath);
    },
    sendAnalyticsCRMUserId(): void {
      if(process.env.VUE_APP_BRAND_GTM_ID) {
        const userIdPrefix = this.isWinnder ? 'W' : '';
        window.dataLayer.push({'crm_id': userIdPrefix + this.userId});
      }
    },
    sendAnalyticsEvent(action: string, eventParams = {}): void {
      if(process.env.VUE_APP_BRAND_GTM_ID) {
        window.dataLayer.push({'event': action, 'event_params': eventParams});
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pushCookieConsentDataLayer(...data: any[]): void {
      if(process.env.VUE_APP_BRAND_GTM_ID){
        // eslint-disable-next-line
        window.dataLayer.push(arguments);
      }
    },
    canShowModal(modalName: string, minutesInterval: number = null): boolean {
      let canShow = true;
      const modalLastSeenValue = this.$store.state.modalsSeen[modalName] || null;
      if (modalLastSeenValue) {
        let minutes = minutesInterval;
        if (minutes === null) {
          minutes = MODALS_SEEN_MINUTES[modalName] || 0;
        }

        const lastSeen = moment.utc(modalLastSeenValue);
        if (lastSeen.isValid()) {
          lastSeen.add(minutes, 'minutes');
          if (moment.utc().isBefore(lastSeen)) {
            canShow = false;
          }
        }
      }

      return canShow;
    },
    updateModalLastSeen(modalName: string): void {
      this.$store.dispatch(ModalsSeenActionTypes.UPDATE_MODAL_LAST_SEEN, modalName);
    },
    async onLoginSuccess(token: string) {
      await this.$store.dispatch(AuthActionTypes.LOGIN, token);

      if (this.$store.getters.nextUrl) {
        this.goToNextUrl();
      } else {
        this.$router.push({name: ROUTES.lobby.name, params: {lang: this.routeLang}});
      }

      this.sendLegacyNativeAppEvent(TO_NATIVE_APP_EVENTS.LOGIN);
    },
    routerPush(route: string|Location, params: Record<string, any> = {}) {
      const pushRoute: Location = typeof route === 'string' ? {name: route, params} : route;
      const pushRouteParams = pushRoute.params ? pushRoute.params : {};

      this.$router.push({...pushRoute, params: {...pushRouteParams, lang: this.routeLang}});
    },
    routerPathPush(path: string) {
      this.$router.push((this.routeLang ? ('/' + this.routeLang) : '') + path);
    },
    sendLegacyNativeAppEvent(type: string, data: Record<string, any>|number|string|any[] = null) {
      if (isLegacyNativeApp()) {
        window.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({type, data}));
      }
    },
    openLink(url: string) {
      openLink(url)
    },
  },
}));

declare module 'vue/types/vue' {
  interface Vue {
    isDesktop: boolean;
    isTablet: boolean;
    isMobile: boolean;
    isLoggedIn: boolean;
    isNativeApp: boolean;
    canInstallApp: boolean;
    acceptCookies: boolean;
    userId: number | null;
    username: string | null;
    currentLang: string;
    routeLang: string|null;
    walletQuantity: number;
    hasFreeMatch: boolean;
    freeMatchQuantity: number;
    appLogo: string;
    appMobileLogo: string;
    appName: string;
    appCompany: string;
    brandId: number;
    brandIds: typeof BRAND_IDS;
    isWinnder: boolean;
    isGamersfy: boolean;
    isColiseum: boolean;
    adsLoaded: boolean;
    hasAds: boolean;
    hasWebAds: boolean;
    hasCrossSelling: boolean;
    allowDeposit: boolean;
    appVisible: boolean;
    showLoader: (show: boolean) => Promise<void>;
    showToast: (title: string, message: string, level?: string, duration?: number, extraOptions?: BvToastOptions) => void;
    showToastError: (title: string, message: string, duration?: number, extraOptions?: BvToastOptions) => void;
    showToastSuccess: (title: string, message: string, duration?: number, extraOptions?: BvToastOptions) => void;
    getUserTimezone: () => string;
    getUserDate: (date: string, dateFormat?: string, dateTimezone?: string) => moment.Moment;
    formatUserDateTime: (date: string, dateFormat?: string, dateTimezone?: string) => string;
    formatUserDate: (date: string, dateFormat?: string, dateTimezone?: string) => string;
    formatUserTime: (date: string, dateFormat?: string, dateTimezone?: string) => string;
    getUrlImg: (segment: string, img: string) => string;
    getAvatar: (avatar: any) => string;
    formatPrice: (amount: number, addSymbol?: boolean, numDecimals?: number, removeDecimalsIfPossible?: boolean, amountConverted?: boolean) => string;
    goToMessageUser: (username: string) => void;
    showModal: (options: ShowModalOptions) => void;
    createMatch: (data?: {gameAlias?: string, gameConfigured?: boolean, competitionProposal?: {username: string, competitionId?: number, gameId?: number}}) => void;
    goToSignup: () => void;
    showModalById: (id: string, modalProps?: Record<string, any>) => void;
    hideModalById: (id: string, removeModalProps?: boolean) => void;
    showModalByIdWithDelay: (id: string, modalProps?: Record<string, any>, delay?: number) => void;
    cancelShowModalByIdWithDelay: (id: string) => void;
    scrollToTop: () => void;
    setPageData: (data: any) => void;
    setNextUrl: (path: string) => void;
    goToNextUrl: () => void;
    getCommonImageSrc: (imagePath: string) => string;
    getBrandImageSrc: (imagePath: string) => string;
    sendAnalyticsCRMUserId: () => void;
    sendAnalyticsEvent: (action: string, eventParams?: Record<string, any>) => void;
    pushCookieConsentDataLayer: (...items: any[]) => void;
    canShowModal: (modalName: string, minutesInterval?: number) => boolean;
    updateModalLastSeen: (modalName: string) => void;
    onLoginSuccess: (token: string) => Promise<void>;
    routerPush: (route: string|Location, params?: Record<string, any>) => void;
    routerPathPush: (path: string) => void;
    sendLegacyNativeAppEvent: (type: string, data?: Record<string, any>|number|string|any[]) => void;
    openLink: (url: string) => void;
  }
}
