export const MODAL_IDS = {
  LOGIN: 'loginModal',
  SIGN_UP: 'signUpModal',
  FORGOT_PASSWORD: 'forgotPasswordModal',
  CREATE_MATCH: 'createMatch',
  WALLET: 'walletModal',
  DEPOSIT: 'depositModal',
  DEPOSIT_REMINDER: 'depositReminderModal',
  WITHDRAW: 'withdrawModal',
  SUPPORT: 'supportModal',
  FOLLOWERS: 'followersModal',
  ADD_GAME: 'addGameModal',
  INSTALL_PWA: 'installPwa',
  MODAL_PLAY_GAME: 'modal-play-game',
  MODAL_PRACTICE_GAME: 'modal-practice-game',
  MODAL_REPORT_USER: 'modal-report-user',
  ALLOW_ADS_MODAL: 'allow-ads-modal',
  CHALLENGE_UPLOAD_MODAL: 'challenge-upload-modal',
  ADS_BLOCKED_MODAL: 'ads-blocked-modal',
  CREATE_INDIVIDUAL_COMPETITION: 'create-individual-competition',
  RESULT_INDIVIDUAL_COMPETITION: 'result-individual-competition',
  USER_BLOCK_MANAGEMENT: 'user-block-management',
  USER_PROMOTION: 'user-promotion',
};

export const MODALS_LOADED = {
  [MODAL_IDS.LOGIN]: false,
  [MODAL_IDS.SIGN_UP]: false,
  [MODAL_IDS.FORGOT_PASSWORD]: false,
  [MODAL_IDS.CREATE_MATCH]: false,
  [MODAL_IDS.WALLET]: false,
  [MODAL_IDS.DEPOSIT]: false,
  [MODAL_IDS.WITHDRAW]: false,
  [MODAL_IDS.SUPPORT]: false,
  [MODAL_IDS.FOLLOWERS]: false,
  [MODAL_IDS.ADD_GAME]: false,
  [MODAL_IDS.INSTALL_PWA]: false,
  [MODAL_IDS.ALLOW_ADS_MODAL]: false,
};

export const MODALS_SEEN_MINUTES = {
  [MODAL_IDS.INSTALL_PWA]: 12 * 60, // 12 horas
  [MODAL_IDS.DEPOSIT_REMINDER]: 24 * 60, // 24 horas
};
