
  import Vue from "vue";

  export default Vue.extend({
    name: "pwa-carousel",
    props: {
      banners: {
        type: Array,
        required: true,
      }
    },
    methods: {
      async onClickBanner(banner: any) {
        if (banner.url) {
          if (banner.url.charAt(0) === '/') {
            this.routerPathPush(banner.url);
          } else {
            this.openLink(banner.url);
          }
        }
      }
    }
  });
